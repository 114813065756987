<script>
  import { inertia } from '@inertiajs/svelte'

  import {
    EventLayout,
    EventDetails,
    EventAttendance,
    EventHeader
  } from '$lib/components'

  /** @type {import('$lib/types').Event} */

  /** @type {{ event: import('$types').Event, current_attendance?: import('$types').Attendance }} */
  let { event, current_attendance } = $props()
</script>

{#snippet photo()}
  <a use:inertia href="/events/{event.id}" class="[&_img]:group-hover:shadow-secondary/25 relative">
    <EventHeader {event} {current_attendance} />

    <div class="absolute top-3 right-3">
      <kbd class="kbd kbd-sm shadow">1</kbd>
    </div>
  </a>
{/snippet}

{#snippet primary_details()}
  <a use:inertia href="/events/{event.id}" class="max-md:hidden">
    <h1 class="text-4xl font-bold">{event.title}</h1>
  </a>

  <div class="md:order-last">
    <EventAttendance {event} {current_attendance} />
  </div>

  <div class="space-y-3">
    <EventDetails {event} {current_attendance} />
  </div>
{/snippet}

<EventLayout
  class="group"
  {photo}
  {primary_details}
/>
