<script>
  import { onMount, untrack } from 'svelte'
  import { page } from '@inertiajs/svelte'

  import { Modal, Form, Loadable, Pages, ContactList, NewContactForm, ButtonNotifier } from '$lib/components'
  import { copy_to_clipboard, group_array } from '$lib/utilities'
  import { misc } from '$lib/stores'

  import InvitationPreview from './InvitationPreview.svelte'

  let { event, current_user } = $derived($page.props)

  let { as_hosts = $bindable(false), ...restProps } = $props()

  /** @type {import('$lib/types').Contact[]} */
  let contacts = $state([])

  /** @type {boolean} */
  let is_loading = $state(true)

  $effect(() => {
    if (modal_open) {
      untrack(() => fetch_contacts())
    }
  })

  onMount(() => {
    let url = new URL($page.props.url)

    if (!as_hosts && url.searchParams.has('invite')) {
      setTimeout(() => modal_open = true, 300)

      url.searchParams.delete('invite')

      window.history.replaceState(null, '', url.toString())
    }
  })

  const fetch_contacts = async () => {
    if (!contacts.length) is_loading = true

    contacts = await fetch(`/contacts?event_id=${event.id}&expand[]=contact.has_mutual_events&expand[]=contact.has_been_hosted`).then(res => res.json())

    if (!contacts.length) pages.navigate(`new-contact-page-${as_hosts}`)

    is_loading = false
  }

  let query = $state('')
  let modal_open = $state(false),
    invitation_page_open = $state(false)

  /** @type {string[]} */
  let selected_item_ids = $state([])

  const reset_modal = () => {
    modal_open = false
    pages.reset()
    selected_item_ids = []
    contacts = []
    query = ''
  }

  /** @type {Pages.Body} */
  let pages

  const SECTION_ORDER = [
    'In your contacts',
    `You've invited to previous events`,
    `You have events in common`,
    'Already invited'
  ]

  /** @param {import('$lib/types').Contact[]} contacts */
  const create_contact_sections = (contacts) => Object.entries(group_array(contacts, ({ is_invited, has_mutual_events, has_been_hosted }) => {
    if (is_invited) return 'Already invited'

    if (has_been_hosted) return `You've invited to previous events`

    if (has_mutual_events) return `You have events in common`

    return 'In your contacts'
  }))
  .sort(([a_title], [b_title]) => SECTION_ORDER.indexOf(a_title) - SECTION_ORDER.indexOf(b_title))
  .map(([title, contacts]) => ({
    title,
    items: title === 'Already invited' ? contacts.map(contact => ({ ...contact, disabled: true })) : contacts
  }))

  /** @type {import('$lib/types').ContactListSection[]} */
  let sections = $derived(create_contact_sections(contacts))

  let total_people = $derived(selected_item_ids.reduce(
    (total, id) => {
      let contact = contacts.find(contact => contact.id === id)
      if (contact) return total + 1

      return total
    }, 0)
  )

</script>

<Modal
  bind:this={$misc.invite_modal}
  bind:open={modal_open}
  class="sm:!max-w-md"
  {...restProps}
>
  <Pages.Body bind:this={pages}>
    {@render contact_list_page()}
    {@render invitations_page()}
    {@render create_contacts_page()}
  </Pages.Body>
</Modal>

{#snippet contact_list_page()}
  <Pages.Page id="invite-list-{as_hosts}" bind:open={invitation_page_open}>
    <h3 class="text-2xl line-clamp-1">{ as_hosts ? 'Add hosts' : 'Invite people' } to <strong>{ event.title }</strong></h3>

    <Loadable loading={is_loading}>
      {#if contacts.length}
        <div class="mt-2">
          {#if event.visibility === 'private'}
            Select who you'd like to invite below or

            <label
              for="new-contact-page-{as_hosts}"
              class="text-emerald-600 underline hover:no-underline cursor-pointer"
            >
              add new people</label>.
          {:else}
            Select who you'd like to invite below,

            <label
              for="new-contact-page-{as_hosts}"
              class="text-emerald-600 underline hover:no-underline cursor-pointer"
            >
              add new people</label>, or
            <ButtonNotifier class="!w-auto [&>.dropdown-content]:mb-0">
              {#snippet children(notify)}
              <button
                class="text-emerald-600 underline hover:no-underline cursor-pointer"
                onclick={async () => await copy_to_clipboard(event.url) && notify('Copied!')}
              >share the link</button>
              {/snippet}
            </ButtonNotifier>.
          {/if}
        </div>

        <ContactList
          visible={modal_open && invitation_page_open}
          {sections}
          bind:selected_item_ids
          bind:query
          empty_text="We couldn't find anyone with those details."
        />

        <label
          class="w-full btn btn-primary"
          class:btn-disabled={!total_people}
          for={ total_people ? `invite-confirmation-${as_hosts}` : '' }
        >
          {#if total_people}
            { as_hosts ? 'Add' : 'Invite'} {total_people} { total_people === 1 ? 'person' : 'people' }
          {:else}
            Select people to { as_hosts ? 'add' : 'invite' }
          {/if}
        </label>
      {/if}
    </Loadable>
  </Pages.Page>
{/snippet}

{#snippet invitations_page()}
  <Pages.Page id="invite-confirmation-{as_hosts}">
    <h3 class="text-2xl font-medium mt-3">Invite <strong>{total_people} {total_people === 1 ? 'person' : 'people'}</strong> to {as_hosts ? 'host ' : ''}{event.title}</h3>

    <Form
      action="/events/{event.id}/invitations"
      onsuccess={reset_modal}>
      <ContactList
        sections={[{
          items: selected_item_ids.reduce(
          /**
           * @param {import('$lib/types').ContactListItem[]} selected_items
           * @param {string} id
           */
          (selected_items, id) => {
            let contact = contacts.find(contact => contact.id === id)
            if (contact) return [...selected_items, contact]

            return selected_items
          }, [])
        }]}
        selectable={false}
        searchable={false}
        let:item
      >
        <input type="hidden" name="users[]id" value={item.id}>

        {#if as_hosts}
          <input type="hidden" name="users[]can_host" value={true}>
        {/if}
      </ContactList>

      {#if as_hosts}
        <p class="mt-3 text-rose-500">Hosts can invite people, invite others to host, send event updates and change event details. They can also see the names for any contacts you've uploaded.</p>

        <p class="mt-3">We'll send them an invitation to host, which they first need to accept.</p>
      {/if}

      <InvitationPreview {as_hosts} class="mt-3" invite_count={total_people} />

      <button
        type="submit"
        class="btn btn-primary w-full mt-6"
      >Send {total_people} invitation{total_people === 1 ? '' : 's'}</button>
    </Form>
  </Pages.Page>
{/snippet}

{#snippet create_contacts_page()}
  <Pages.Page id="new-contact-page-{as_hosts}" hideBackButton={!contacts.length}>
    {@const can_whatsapp_upload = !as_hosts && current_user?.notification_channels?.some(({ name, is_active }) => is_active && name.includes('whatsapp')) }
    <h3 class="text-2xl font-medium mt-3">{as_hosts ? 'Add' : 'Invite'} new people</h3>

    <p class="my-2">You can {#if can_whatsapp_upload}upload contacts through WhatsApp,{/if} import {can_whatsapp_upload ? 'them' : 'contacts'} from a spreadsheet or enter their details below.</p>

    {#if can_whatsapp_upload}
      <div>
        <Form
          action="/events/{event.id}/contact_upload_requests"
          onsuccess={ () => pages.reset() }
        >
          <button
            type="submit"
            class="w-full btn btn-primary mt-2"
          >Upload contacts</button>
        </Form>

        <div class="divider text-neutral/50">Or</div>
      </div>
    {/if}

<!--       <label
      for="import-spreadsheet-contacts-{as_hosts}"
      class="w-full btn btn-primary"
    >Import from spreadsheet</label>

    <div class="divider text-neutral/50">Or</div> -->

    <NewContactForm
      onsuccess={() => {
        fetch_contacts()
        pages.reset()
      }}
    />
  </Pages.Page>
{/snippet}