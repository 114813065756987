<script>
  import { getContext, setContext } from 'svelte'
  import { random_id, handle_shortcuts } from '$lib/utilities'

  const pages = getContext('pages')

  /** @type {{
    id?: string,
    hideBackButton?: boolean,
    backPage?: string,
    class?: string,
    onopen?: function():any,
    onclose?: function():any,
    open?: boolean,
    children: import('svelte').Snippet
    corner?: import('svelte').Snippet
  }} */
  let {
    id = random_id(),
    hideBackButton = false,
    backPage = undefined,
    class: class_name = '',
    onopen,
    onclose,
    children,
    corner,
    open = $bindable(pages.active_page === id),
    ...restProps
  } = $props()

  setContext('container', {
    get open() { return pages.active_page === id }
  })

  if (!pages.initial_page) {
    pages.push(id)
  }

  $effect(() => {
    open = pages.active_page === id
  })

  handle_shortcuts({
    escape: (evt) => {
      if (pages.active_page === id && id !== pages.initial_page) {
        evt.stopPropagation()
        pages.pop()
      }
    }
  })
</script>

<input
  {id}
  type="radio"
  class="hidden"
  name="pages-{pages.id}"
  oninput={() => pages.push(id)}
  checked={id === pages.active_page}
  {...restProps}
/>

<div
  class="page {pages.initial_page && 'initial'} {class_name}"
  ontransitionend={(evt) => {
    if (evt.propertyName === 'opacity') {
      if (pages.active_page === id) {
        onopen && onopen()
      } else {
        onclose && onclose()
      }
    }
  }}
>
  <div class="flex justify-between items-center mb-2">
    {#if id !== pages.initial_page && !hideBackButton}
      <label
        for={backPage || pages.initial_page}
        onclick={() => pages.pop()}
        aria-hidden="true"
        tabindex="-1"
        class="text-gray-500 cursor-pointer inline-block"
        >&larr; Back</label>
    {/if}

    {#if corner}
      {@render corner()}
    {/if}
  </div>

  {@render children()}
</div>

<style lang="postcss">
  .page {
    transition: transform 300ms, opacity 250ms;

    @apply opacity-0 pointer-events-none z-[0] translate-x-10;
  }

  input[type="radio"]:not(:checked) + .page {
    animation: hide 75ms forwards;
  }

  .page.initial {
    @apply -translate-x-10;
  }

  input[type="radio"]:checked + .page {
    @apply opacity-100 translate-x-0 max-h-full pointer-events-auto z-[1];
  }

  @keyframes hide {
    0%, 99% {
      max-height: 100vh;
    }

    100% {
      max-height: 0px;
    }
  }

</style>
