<script>
  import {
    parseJSON,
    isSameDay,
    isSameMonth,
    isSameYear,
    differenceInCalendarDays
  } from 'date-fns'

  import { SlideTransition, Tooltip } from '$lib/components'
  import { format_datetime } from  '$lib/utilities'

  /** @type {{
    event: import('$types').Event,
    current_attendance?: import('$types').Attendance
  }} */
  let { event, current_attendance } = $props()

  let can_see_location = $derived(!event.visible_location || current_attendance?.status === 'going' || current_attendance?.is_host)

  let start = $derived(parseJSON(event.start_time)),
       end = $derived(parseJSON(event.end_time))

  let is_same_day = $derived(
    isSameDay(start, end) || (differenceInCalendarDays(end, start) === 1 && format_datetime(end, 'HHmm') == '0000')
  )

  let [month, day] = $derived(format_datetime(start, 'MMM d').split(' '))

  let event_date = $derived.by(() => {
    const year_template = isSameYear(new Date(), start) ? '' : ', yyyy'

    if (is_same_day)
      return format_datetime(start, `EEEE do MMMM${year_template}`)

    if (isSameMonth(start, end))
      return format_datetime(start, `EEEE do`) +
            ' <span class="text-muted">to</span> ' +
             format_datetime(end, `EEEE do MMMM${year_template}`)

    if (isSameYear(start, end))
      return format_datetime(start, `EEEE do MMM`) +
            ' <span class="text-muted">to</span> ' +
             format_datetime(end, `EEEE do MMM${year_template}`)


    return format_datetime(start, `EEE do MMM${year_template}`) +
          ' <span class="text-muted">to</span> ' +
           format_datetime(end, `EEE do MMM, yyyy`)
  })

  let start_time = $derived(format_datetime(start, 'HH:mm'))

  const end_time = $derived.by(() => {
    let time = format_datetime(end, 'HH:mm')

    if (is_same_day && time < start_time) return 'late'

    return time
  })
</script>

<div class="flex items-center space-x-3">
  <div class="detail-icon">
    <div class="flex flex-col space-y-1">
      <span class="text-xs leading-none uppercase text-success">{month}</span>
      <span class="text-base leading-none">{day}</span>
    </div>
  </div>

  <div>
    <p class="text-lg">
      {@html event_date}
    </p>

    {#if !event.is_all_day}
      <p class="leading-none text-muted">
        {start_time} - {end_time}
      </p>
    {/if}
  </div>
</div>

<!-- Price -->
<!-- {#if event.ticket_price > 0}
  <div class="mt-3 flex space-x-3">
    <span class="i-heroicons-currency-pound mt-1 h-5 w-5 text-secondary"></span>

    <SlideTransition key={event.ticket_price}>
      <p class="break-words text-lg">
        <span class="text-sm text-muted">£</span>{ (event.ticket_price / 100).toFixed(2) }
      </p>
    </SlideTransition>
  </div>
{/if} -->

<!-- Location -->
<div class="flex items-center space-x-3">
  {#if event.visible_location && current_attendance?.is_host}
    <Tooltip text="People who haven't RSVP'd going will only see <span class='font-semibold'>{event.visible_location}</span>." container_class="dropdown-top md:dropdown-center" class="mb-1.5 w-64">
      <div class="indicator">
        <span class="indicator-item badge bg-white shadow shadow-amber-500/75 badge-sm border-transparent h-4 w-4">
          <span class="text-amber-500">!</span>
        </span>

        <div class="detail-icon">
          <span class="h-7 w-7 text-success i-mdi-map-marker-off-outline"></span>
        </div>
      </div>
    </Tooltip>
  {:else}
    <div class="detail-icon">
      <span class="h-7 w-7 text-success i-mdi-map-marker-outline"></span>
    </div>
  {/if}

  <SlideTransition key={can_see_location}>
    <div class="grid gap-1">
      <p class="break-words text-lg leading-none">
        {#if can_see_location}
          {#if event.location.source === 'google'}
            <a href="https://www.google.com/maps/search/?api=1&query={event.location.formatted_address}&query_place_id={event.location.source_id}" target="_blank" rel="noreferrer" class="text-success hover:underline">
              {event.location.formatted_address}

              <span class="i-feather-external-link h-3.5 w-3.5 !inline-block text-muted"></span>
            </a>
          {:else}
            {event.location.formatted_address}
          {/if}
        {:else}
          {event.visible_location}
        {/if}
      </p>

      {#if !can_see_location}
        <p class="text-muted text-sm leading-none">RSVP going to see the full location</p>
      {/if}
    </div>
  </SlideTransition>
</div>

<style lang="postcss">
  .detail-icon {
    @apply h-11 w-11 flex-shrink-0 flex items-center justify-center shadow shadow-secondary/25 border rounded-btn text-center font-semibold hover:shadow-md transition-shadow;
  }
</style>

