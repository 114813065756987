import { on } from 'svelte/events'
import { onMount } from 'svelte'
import { random_id } from './random_id'

/** @type {[string, Record<string, function(KeyboardEvent):any>][]} */
let listeners = $state([])

/** @param {Record<string, function(KeyboardEvent):any>} config */
export const handle_shortcuts = (config) => {
  if (typeof window === 'undefined') return () => {}

  onMount(() => {
    const id = random_id()

    listeners.unshift([id, config])

    return () => {
      const index = listeners.findIndex(([listener_id]) => id === listener_id)

      listeners.splice(index, 1)
    }
  })
}

if (typeof window !== 'undefined') {
  on(window, 'keydown', (evt) => {
    const is_input =
      ['INPUT', 'TEXTAREA'].includes(evt.target?.tagName) &&
      !['checkbox', 'radio'].includes(evt.target?.type)

    if (is_input) return

    let is_propagation_stopped = false
    const original_stop_propagation = evt.stopPropagation
    evt.stopPropagation = () => {
      is_propagation_stopped = true
      original_stop_propagation.call(evt)
    }

    let key = evt.key.toLowerCase()

    // if (evt.getModifierState('CapsLock')) key = key.toLowerCase()

    if (evt.metaKey || evt.getModifierState('OS') /* Windows Key */) key = 'mod_' + key
    if (evt.ctrlKey) key = 'ctrl_' + key

    for (let listener of listeners) {
      if (is_propagation_stopped) return

      const config = listener[1][key]

      if (config) {
        evt.preventDefault()
        config.call(null, evt)
      }
    }
  })
}
