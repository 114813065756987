<script>
  import { inertia, router } from '@inertiajs/svelte'

  import { Modal, Form, Pages, AddToCalendarForm, InvitationModal, ExportModal, ButtonNotifier } from '$lib/components'
  import DeleteEventModal from './DeleteEventModal.svelte'

  import { copy_to_clipboard, notify, handle_shortcuts } from '$lib/utilities'

  /** @type {{ event: import('$types').Event, current_attendance: import('$types').Attendance }} */
  let { event, current_attendance } = $props()

  let attendance_limit_reached = $derived(event.attendance_limit && event.attendee_stats.going >= event.attendance_limit)

  let user_can_invite = $derived(current_attendance && (current_attendance?.is_host || event.guests_can_invite))

  let button_class = 'btn btn-outline'

  let invitation_modal_label = $state(),
          delete_event_label = $state()

  handle_shortcuts({
    d: () => current_attendance?.is_host && router.visit(`/events/${event.id}/edit`),
    i: () => invitation_modal_label?.click(),
    'mod_Backspace': () => delete_event_label.click()
  })
</script>

<div class="flex justify-between">
  <div class="join">
    <label class="{button_class} join-item" for="add-to-calendar">
      <span class="i-feather-calendar"></span>
      <span class="{ user_can_invite ? 'max-sm:hidden' : '' }">Add to calendar</span>
    </label>

    {#if event.visibility !== 'private'}
      <button
        class="{button_class} join-item"
        onclick={async () => {
          if (navigator.share) {
            try {
              navigator.share({ url: event.url })
            } catch {
              await copy_to_clipboard(event.url)
            }
          } else {
            await copy_to_clipboard(event.url)
          }
        }}
      >
        <span class="i-feather-share"></span>
        <span class="{ user_can_invite ? 'max-sm:hidden' : '' }">Share</span>
      </button>

      <ButtonNotifier>
        {#snippet children(notify)}
          <button
            aria-label="Copy to clipboard"
            class="{button_class} btn-square join-item"
            onclick={async () => await copy_to_clipboard(event.url) && notify('Copied!')}
          >
            <span class="i-feather-link h-[1.125rem] w-[1.125rem]"></span>
          </button>
        {/snippet}
      </ButtonNotifier>
    {/if}
  </div>

  <div>
    <div class="flex gap-2">
      {#if user_can_invite}
        <!-- svelte-ignore a11y_click_events_have_key_events -->
        <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
        <label
          bind:this={invitation_modal_label}
          for="invite-modal"
          class="{button_class} btn-accent shadow-md shadow-accent/20"
          onclick={ (evt) => {
            if (attendance_limit_reached) {
              evt.preventDefault()

              notify('event_invite_attendance_limit_reached', { event: event })
            }
          }}
        >
          <span class="i-feather-user-plus"></span>
          Invite
          <kbd class="kbd kbd-sm font-normal border-accent/50 -mr-1.5">i</kbd>
        </label>
      {/if}

      {#if current_attendance?.is_host}
        <div class="relative dropdown dropdown-end dropdown-hover">
          <div tabindex="0" role="button" class="{button_class} btn-square flex-shrink-0">
            <span class="i-feather-chevron-down h-5 w-5"></span>
          </div>

          <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
          <div tabindex="0" class="dropdown-content pt-2 z-[1]">
            <ul class="p-2 shadow-md menu bg-base-100 rounded-box w-52 border border-base-200">
              <li>
                <a use:inertia href="/events/{event.id}/edit">
                  <span class="i-feather-edit-3"></span>
                  Edit
                  <kbd class="kbd kbd-sm">d</kbd>
                </a>
              </li>

              <li>
                <!-- svelte-ignore a11y_click_events_have_key_events -->
                <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
                <label
                  for="invite-hosts-modal"
                  onclick={ (evt) => {
                    if (attendance_limit_reached) {
                      evt.preventDefault()

                      notify('event_invite_attendance_limit_reached', { event: event })
                    }
                  }}
                >
                  <span class="i-mdi-crown-outline"></span>
                  Add hosts
                </label>
              </li>

              <Form action="/events/{event.id}/duplicates">
                <li>
                  <button>
                    <span class="i-feather-copy"></span>
                    Duplicate
                  </button>
                </li>
              </Form>

              <li>
                <label for="export-modal">
                  <span class="i-mdi-grid"></span>
                  Export
                </label>
              </li>

              <li>
                <label for="delete-event-modal" class="text-rose-500" bind:this={delete_event_label}>
                  <span class="i-feather-trash-2"></span>
                  Delete

                  <div class="flex gap-1">
                    <kbd class="kbd kbd-sm bg-white">⌘</kbd>
                    <kbd class="kbd kbd-sm bg-white">d</kbd>
                  </div>
                </label>
              </li>
            </ul>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<Modal id="add-to-calendar">
  <Pages.Body>
    <AddToCalendarForm />
  </Pages.Body>
</Modal>

{#if current_attendance?.is_host}
  <InvitationModal as_hosts id="invite-hosts-modal" />

  <ExportModal id="export-modal" />

  <DeleteEventModal id="delete-event-modal" />
{/if}

{#if current_attendance && (current_attendance?.is_host || event.guests_can_invite)}
  <InvitationModal id="invite-modal" />
{/if}

<style lang="postcss">
  .btn {
    @apply min-h-10 h-10;
  }
</style>