/**
 * Creates a debounced version of a function that delays its execution.
 *
 * @param {T} mainFunction - The main function to debounce.
 * @param {number} delay - The delay in milliseconds for debouncing.
 * @returns {T} A debounced version of the given function.
 */
export const debounce = <T extends (...args: any[]) => void>(mainFunction: T, delay: number): T => {
  let timer: ReturnType<typeof setTimeout>

  return ((...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      mainFunction(...args)
    }, delay)
  }) as T
}
