<script>
  import { page } from '@inertiajs/svelte'
  import { Form, Tooltip } from '$lib/components'
  import { handle_shortcuts } from '$lib/utilities'

  import EventEntry from './EventEntry.svelte'

  import CreatePollModal from './CreatePollModal.svelte'
  import CreateGroupChatModal from './CreateGroupChatModal.svelte'
  import BroadcastEntryModal from './BroadcastEntryModal.svelte'

   /** @type {import('$types').PageProps<'current_attendance'>} */
  let { current_attendance } = $derived($page.props)

  /** @type {{ event: import('$types').Event }} */
  let { event } = $props()

  /** @type {import('$lib/types').Entry} */
  let latest_entry = $state()

  let post_value = $state('')

  let post_input_el = $state()

  let is_post_input_el_focused = $state(false)

  /** @type {boolean} */
  let is_latest_entry_broadcast_open = $state(false)

  handle_shortcuts({
    p: () => {
      if (current_attendance && (event.guests_can_post || current_attendance.is_host))
        post_input_el.focus()
    }
  })
</script>

<div>
  <div class="flex justify-between items-center">
    {#if !event.guests_can_post && current_attendance?.is_host}
      <Tooltip text="Only hosts can post updates." container_class="dropdown-top md:dropdown-center" class="mb-1.5 w-[13.5rem]">
        <div class="flex gap-1">
          <h3 class="text-lg font-semibold">Updates</h3>

          <span class="badge bg-white shadow shadow-amber-500/75 badge-sm border-transparent h-4 w-4 mt-1">
            <span class="text-amber-500">!</span>
          </span>
        </div>
      </Tooltip>
    {:else}
      <h3 class="text-lg font-semibold">Updates</h3>
    {/if}
  </div>

  {#if current_attendance}
    {#if event.guests_can_post || current_attendance.is_host}
      <div class="relative mt-3">
        <Form
          action="/entries"
          onsuccess={(page) => {
            post_value = ''

            latest_entry = page.props.event.entries[0]

            is_latest_entry_broadcast_open = true
          }}
        >
          <input type="hidden" name="entryable_type" value="Post">
          <input type="hidden" name="event_id" value={event.id}>

          <div class="flex items-start space-x-3">
            <label class="relative flex-1">
              <textarea
                bind:this={post_input_el}
                id="new-post-input"
                placeholder="Post an update&hellip;"
                class="textarea textarea-bordered w-full flex-1 min-h-10 py-1.5 h-10"
                name="entryable_attributes[text]"
                required
                bind:value={post_value}
                onfocus={() => is_post_input_el_focused = true}
                onblur={() => is_post_input_el_focused = false}
                onkeydown={(evt) => {
                  if (evt.key === 'Escape') return post_input_el.blur()

                  if (evt.key === 'Enter' && evt.metaKey) {
                    post_input_el.closest('form').dispatchEvent(new SubmitEvent('submit'))
                  }
                }}
                rows={1}
              ></textarea>

              <div class="absolute h-10 flex items-center top-0 right-2 gap-1">
                {#if is_post_input_el_focused}
                  <kbd class="kbd kbd-sm">⌘</kbd>
                  <kbd class="kbd kbd-sm">⏎</kbd>
                {:else}
                  <kbd class="kbd kbd-sm">p</kbd>
                {/if}
              </div>
            </label>

            <div class="join">
              <button type="submit" class="btn btn-outline join-item">
                <span class="i-feather-edit"></span>
                Post
              </button>

              {#if current_attendance?.is_host}
                <div class="relative dropdown dropdown-end dropdown-hover">
                  <div tabindex="0" role="button" class="btn btn-outline btn-square join-item">
                    <span class="i-feather-chevron-down h-5 w-5"></span>
                  </div>

                  <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
                  <div tabindex="0" class="dropdown-content pt-2 z-[1]">
                    <ul class="p-2 shadow menu bg-base-100 rounded-box">
                  <!--     <li>
                        <label for="create-poll-modal" class="whitespace-nowrap">
                          <span class="i-feather-bar-chart-2"></span>
                          Create a poll
                        </label>
                      </li> -->

                      <li>
                        <label for="create-group-chat-modal" class="whitespace-nowrap">
                          <span class="i-ion-chatbubbles-outline"></span>
                          Create a group chat
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </Form>

        {#if current_attendance?.is_host}

  <!--         <CreatePollModal
            id="create-poll-modal"
            on:success={ (evt) => {
              latest_entry = evt.detail
              setTimeout(() => is_latest_entry_broadcast_open = true, 500)
            }}
          />
   -->
          <CreateGroupChatModal
            id="create-group-chat-modal"
            on:success={ (evt) => {
              latest_entry = evt.detail
              setTimeout(() => is_latest_entry_broadcast_open = true, 500)
            }}
          />

          <!-- Broadcast modal for the latest entry -->
          <BroadcastEntryModal
            bind:open={is_latest_entry_broadcast_open}
            entry={latest_entry}
          />
        {/if}
      </div>
    {/if}

    <ul class="divide-y divide-base-content/20">
      {#if event.entries.length}
        {#each event.entries as entry (entry.id)}
          <EventEntry {entry} />
        {/each}
      {:else if !event.guests_can_post && !current_attendance?.is_host}
        <p class="mt-1 text-muted">No updates yet</p>
      {/if}
    </ul>
  {:else}
    <p class="mt-1 text-muted">RSVP for updates</p>
  {/if}
</div>

<style lang="postcss">
  .btn {
    @apply min-h-10 h-10;
  }
</style>
