import { parsePhoneNumber } from 'awesome-phonenumber'

/**
 * @typedef {Object} ChannelConfig
 * @property {string} name
 * @property {'email' | 'tel'} type
 * @property {string} placeholder
 * @property {string} title
 * @property {string} stylized
 * @property {string} icon
 * @property {function(string):string} format_identifier
 * @property {function(import('$lib/types').NotificationChannel):string} description
 */

/** @type {Record<import('$lib/types').NotificationChannel['name'], ChannelConfig>} */
export const notification_channels = {
  whatsapp: {
    name: 'whatsapp',
    type: 'tel',
    placeholder: '07700 900000',
    title: 'WhatsApp',
    stylized: 'WhatsApp',
    icon: 'i-ion-logo-whatsapp',

    /** @param {string} identifier */
    format_identifier: (identifier) => String(parsePhoneNumber('+' + identifier).number?.national),

    description: (channel) =>
      channel.activated_at
        ? `${channel.is_active ? "We'll" : 'We will not'} send WhatsApp notifications to <strong>${parsePhoneNumber(channel.identifier).number?.national}</strong>.`
        : `We've sent an activation message to <strong>${parsePhoneNumber(channel.identifier).number?.national}</strong>.`
  },

  email: {
    name: 'email',
    type: 'email',
    placeholder: 'you@hello.com',
    title: 'Email',
    stylized: 'email',
    icon: 'i-ion-mail-outline',

    /** @param {string} identifier */
    format_identifier: (identifier) => identifier,
    description: (channel) =>
      channel.activated_at
        ? `${channel.is_active ? "We'll" : 'We will not'} email notifications to <strong>${channel.identifier}</strong>.`
        : `We've sent an activation message to <strong>${channel.identifier}</strong>.`
  },

  signal: {
    name: 'signal',
    type: 'tel',
    placeholder: '07700 900000',
    title: 'Signal',
    stylized: 'Signal',
    icon: 'i-occasionly-signal-outline',

    /** @param {string} identifier */
    format_identifier: (identifier) => String(parsePhoneNumber('+' + identifier).number?.national),
    description: (channel) =>
      channel.activated_at
        ? `${channel.is_active ? "We'll" : 'We will not'} send Signal notifications to <strong>${parsePhoneNumber(channel.identifier).number?.national}</strong>.`
        : `We've sent an activation message to <strong>${parsePhoneNumber(channel.identifier).number?.national}</strong>.`
  },

  telegram: {
    name: 'telegram',
    type: 'tel',
    placeholder: '07700 900000',
    title: 'Telegram',
    stylized: 'Telegram',
    icon: 'i-uit-telegram-alt',

    /** @param {string} identifier */
    format_identifier: (identifier) => String(parsePhoneNumber('+' + identifier).number?.national),
    description: (channel) =>
      channel.activated_at
        ? `${channel.is_active ? "We'll" : 'We will not'} send Telegram notifications to <strong>${parsePhoneNumber(channel.identifier).number?.national}</strong> (Telegram User ID).`
        : `We've sent an activation message to <strong>${parsePhoneNumber(channel.identifier).number?.national}</strong>.`
  }
}

export default notification_channels
