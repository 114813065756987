<script>
  import { page, inertia } from '@inertiajs/svelte'
  import { Form, SlideTransition, PricingChecklist, ProSubscriptionButton } from '$lib/components'

  const free_items = [
    'Unlimited events & attendees',
    'Create events magically from text',
    'Embed events anywhere',
    '8% fee on paid events'
  ]

  const pro_items = [
    'Everything in Free',
    'Export attendees to a CSV',
    'Magically generate event images',
    'Dedicated support',
    '🔜 Sync with Google Sheets'
  ]

  let is_annual = $state(false)

  let { current_user } = $props()
</script>

<svelte:head>
  <title>Pricing • Occasionly</title>
</svelte:head>

<div class="relative isolate bg-base-100 dark:bg-base-300 px-6 py-24 sm:py-32 lg:px-8 not-prose">
  <div class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl" aria-hidden="true">
    <div class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-emerald-500 to-teal-400 opacity-30" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
  </div>

  <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
    <h2 class="text-base font-semibold leading-7 text-secondary">Pricing</h2>
    <p class="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">Simple pricing, no commitment</p>
  </div>

  <p class="mx-auto mt-6 max-w-lg text-center text-lg leading-8">
    Free for everyone, and a growing suite of tools for the dedicated hosts who need more from their event planner.
  </p>

  <div class="mt-16 flex justify-center">
    <div class="p-1 ring-base-100 bg-base-100/50 ring-1 ring-inset rounded-full">
      <div class="relative grid grid-cols-2 gap-x-5 items-center p-1 px-3 text-center text-xs font-semibold leading-5">
        <input
          type="checkbox"
          id="payment_frequency"
          bind:checked={is_annual}
          class="peer absolute inset-0 cursor-pointer appearance-none"
        />

        <div class="absolute flex w-auto rounded-full px-3 py-1 bg-primary left-0 peer-checked:left-[calc(100%-75px)] transition-all duration-300">
          <span class="opacity-0">{ is_annual ? 'Annually' : 'Monthly' }</span>
        </div>

        <span class="relative pointer-events-none text-primary-content peer-checked:text-base-content">Monthly</span>
        <span class="relative pointer-events-none text-base-content peer-checked:text-primary-content">Annually</span>
      </div>
    </div>
  </div>


  <div class="mx-auto mt-12 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
    <div class="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 bg-base-100/60 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl">
      <h3 id="tier-casual" class="text-base font-semibold leading-7 text-secondary">Casual</h3>
      <p class="mt-4 flex items-baseline gap-x-2">
        <span class="text-5xl font-bold tracking-tight">Free</span>
      </p>
      <p class="mt-6 text-base leading-7">For the occasional host.</p>
      <ul class="mt-8 space-y-3 text-sm leading-6 sm:mt-10">
        {#each free_items as item}
          <li class="flex gap-x-3">
            <span class="i-feather-check h-6 w-5 text-secondary"></span>

            { item }
          </li>
        {/each}
      </ul>

      <a use:inertia href="/events/new" class="btn btn-outline w-full mt-8 sm:mt-10">Create your event</a>
    </div>

    <div class="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 relative bg-base-100 shadow-2xl">
      <h3 id="tier-enterprise" class="text-base font-semibold leading-7 text-secondary">Pro</h3>
      <SlideTransition key={is_annual} forwards={is_annual}>
        <p class="mt-4 flex items-baseline gap-x-2">
          <span class="text-5xl font-bold tracking-tight">£{is_annual ? '90' : '9' }</span>
          <span class="text-base text-muted">/{is_annual ? 'year' : 'month' }</span>
        </p>
      </SlideTransition>

      <p class="mt-6 text-base leading-7">Dedicated tools for the habitual host.</p>

      <PricingChecklist class="mt-8 text-sm sm:mt-10" items={pro_items} />

      {#if current_user}
        {#if current_user.subscription}
          <div class="text-center mt-8 sm:mt-10">
            <small>You're already subscribed! 💚</small>

            <Form action="/stripe/billing_portal_sessions">
              <button class="w-full btn btn-primary mt-1.5">Manage your plan</button>
            </Form>
          </div>
        {:else}
          <ProSubscriptionButton
            price_id={$page.props.config.pricing[is_annual ? 'annually' : 'monthly']}
            return_url="/events"
            class="mt-6 w-full"
          />
        {/if}
      {:else}
        <label
          for="login-modal"
          class="w-full mt-8 sm:mt-10 btn btn-primary"
        >Sign in to get started today</label>
      {/if}
    </div>

    <div class="mt-12 flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 dark:bg-base-200 ring-boundary sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
      <div class="lg:min-w-0 lg:flex-1">
        <h3 class="text-lg font-semibold leading-8 tracking-tight text-secondary">Custom</h3>
        <p class="mt-1 text-base leading-7">
          Contact us for bespoke integrations, custom ticket pricing, using your own communication channels and white-labeled embeds.
        </p>
      </div>

      <a href="mailto:sales@occasionly.io?subject=Bespoke%20events" class="btn btn-outline">
        Get in touch &rarr;
      </a>
    </div>
  </div>
</div>
