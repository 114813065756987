<script>
  import { page, inertia } from '@inertiajs/svelte'

  import { Tooltip, Form, SlideTransition, NotificationChannelModal, Errors, TimeZonePicker } from '$lib/components'

  import { social_platforms, notification_channels } from '$lib/config'

  /** @type {{ current_user: import('$types').User }} */
  let { current_user } = $props()
</script>

<svelte:head>
  <title>Profile • Occasionly</title>
</svelte:head>

<Form action="/users/{current_user.id}" method="patch" bind_keyboard_submission>
  <div class="grid md:grid-cols-2 gap-x-8 gap-y-5">
    <div class="md:col-span-2 max-md:-mt-2">
      {@render header()}
    </div>

    <div class="grid gap-y-6">
      {@render name_and_socials()}
    </div>

    <div>
      {@render notification_channels_section()}
    </div>
  </div>
</Form>

{#snippet header()}
  <div class="flex justify-between items-center">
    <div>
      <a use:inertia href="/events" class="text-gray-500">&larr; Events</a>
    </div>

    <button
      type="submit"
      class="btn btn-primary h-10 min-h-10"
    >
      Save

      <div class="flex gap-1 -mr-1.5">
        <kbd class="kbd kbd-sm kbd-primary">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-primary">⏎</kbd>
      </div>
    </button>
  </div>
{/snippet}

{#snippet name_and_socials()}
  <div class="flex items-center gap-6">
    {#if current_user.photo_url}
      <Tooltip text="We take your photo from WhatsApp" container_class="dropdown-top" class="w-64 mb-1.5">
        <div class="avatar">
          <div class="w-20 h-20 rounded-full shadow-md">
            <img class="w-full h-full" src={current_user.photo_url} alt={current_user.name} />
          </div>
        </div>
      </Tooltip>
    {:else}
      <div class="avatar">
        <div class="w-20 h-20 rounded-full shadow-md">
          <div class="flex justify-center items-center h-full w-full bg-base-200">
            <span class="i-ion-person w-10 h-10 text-neutral/30"></span>
          </div>
        </div>
      </div>
    {/if}

    <label class="form-control w-full">
      <div class="label pt-0">
        <span class="label-text text-base font-medium">Name</span>
      </div>
      <input
        type="text"
        required
        id="title"
        name="title"
        value={current_user.name}
        class="input input-bordered w-full"
        placeholder="Gaius Pompeius Trimalchio"
      />
    </label>
  </div>

  <div>
    <h3 class="text-xl font-medium">Socials</h3>

    <p class="mt-1 text-sm text-muted">
      Add your social media handles and we'll link to them on guest lists.
    </p>

    <div class="space-y-3 mt-3">
      {#each Object.values(social_platforms) as platform}
        <div class="label py-0">
          <label class="block label-text font-medium text-base" for="socials_{platform.key}">{platform.title}</label>

          <div class="relative">
            <span class="{platform.icon} h-4 w-4 left-3 absolute top-1/2 -translate-y-1/2 text-base-content/70"></span>

            <input
              type="text"
              spellcheck="false"
              class="pl-9 input-sm input input-bordered"
              name="socials_{platform.key}"
              id="socials_{platform.key}"
              value={current_user.socials[platform.key] || null}
            />
          </div>
        </div>
      {/each}
    </div>
  </div>

  <div>
    <h3 class="text-xl font-medium">Time zone</h3>

    <p class="my-1 text-sm text-muted">We use your time zone setting for events and notifications.</p>

    <TimeZonePicker value={current_user.time_zone} />
  </div>
{/snippet}

{#snippet notification_channels_section()}
  <h3 class="text-xl font-medium">Notifications</h3>

  <p class="mt-1 text-sm text-muted">Select where you'd like to receive event invites and notifications.</p>

  <div class="mt-3 space-y-3">
    {#each Object.values(notification_channels) as channel}
      {@const user_channel = current_user.notification_channels.find(({ name }) => name === channel.name)}

      <div>
        <div class="label">
          <label class="block label-text font-medium text-base" for="{channel.name}-channel">{channel.title}</label>

          {#if user_channel}
            <input type="hidden" name="notification_channels_attributes[]id" value={ user_channel.id } />
            <input type="hidden" name="notification_channels_attributes[]is_active" value={ false } />

            <input type="hidden" name="notification_channels_attributes[]id" value={ user_channel.id } />

            <input
              type="checkbox"
              id="{channel.name}-channel"
              name="notification_channels_attributes[]is_active"
              class="toggle toggle-secondary"
              checked={!!user_channel.activated_at && user_channel.is_active}
              disabled={!user_channel.activated_at}
              value={true}
            />
          {:else}
            <label
              for="register-{ channel.name }-modal"
              class="btn btn-outline btn-sm"
            >
              <span class="{channel.icon} i-articons-signal h-4 w-4"></span>

              Add {channel.title}
            </label>
          {/if}
        </div>

        {#if user_channel}
          <div class="label py-0 -mt-1">
            <SlideTransition key={user_channel.is_active}>
              <p class="text-sm text-base-content/70">
                 {@html channel.description(user_channel) }
              </p>
            </SlideTransition>
          </div>
        {/if}
      </div>
    {/each}
  </div>

  <Errors errors={$page.props?.errors?.notification_channels} />
{/snippet}

{#each Object.keys(notification_channels) as channel}
  <NotificationChannelModal {channel} />
{/each
}