<script>
  import { EventForm } from '$lib/components'

  /** @type {{ event: import('$types').Event }} */
  let { event } = $props()

  let _event = $state(event)
</script>

<svelte:head>
  <title>New event • Occasionly</title>
</svelte:head>

<EventForm bind:event={_event} />
