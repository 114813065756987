<script>
  import { page } from '@inertiajs/svelte'
  import { isPast } from 'date-fns'
  import { onMount } from 'svelte'

  import { Modal, Form, Pages, SlideTransition, NewUserFields, TextInput } from '$lib/components'
  import { attendance_label } from '$lib/utilities'

  import { MaybeForm, TicketForm, AdditionalGuestsPage } from './status_forms'

  /** @type {{ event: import('$types').Event, current_attendance: import('$types').Attendance }} */
  let { event, current_attendance } = $props()

  /** @type {Record<string, Modal>} */
  let modals = $state({})

  /** @type {import('$lib/types').Contact[]} */
  let additional_guests = $state([])

  /** @type {'patch' | 'post'} */
  let method = $derived(current_attendance ? 'patch' : 'post')
  let action = $derived(current_attendance ? `/attendances/${current_attendance.id}` : `/attendances`)

  let { going: going_count } = $derived(event.attendee_stats)
  let attendance_limit_reached = $derived(event.attendance_limit && going_count >= event.attendance_limit)
  let user_has_responded = $derived(current_attendance && !['payment_required', 'invited'].includes(current_attendance.status))

  const onsuccess = () => {
    Object.values(modals).forEach(modal => modal.hide())
  }

  let is_past = $derived(isPast(new Date(event.end_time)))

  let show_buttons = $derived(
    !current_attendance ||
    ['invited', 'payment_required'].includes(current_attendance.status) ||
    (is_past && current_attendance.status === 'maybe')
  )

  onMount(() => {
    let url = new URL($page.props.url)

    if (event.ticket_price && url.searchParams.has('buy_ticket')) {
      setTimeout(() => document.querySelector('[for="attendance-ticket"]')?.click(), 300)

      url.searchParams.delete('buy_ticket')

      window.history.replaceState(null, '', url.toString())
    }
  })

  const BUTTON_COLORS = {
    invited: 'btn-primary',
    going: 'btn-primary',
    cannot_go: 'btn-error',
    payment_required: 'btn-warning',
    maybe: 'btn-warning'
  }

  /** @type {Pages.Body} */
  let ticket_pages = $state()
</script>

<svelte:window
  on:keydown|capture={ (evt) => {
    if (!ticket_pages) return;

    if (evt.key === 'Escape' && (!ticket_pages.is_initial() || evt.repeat)) {
      evt.stopPropagation()

      ticket_pages.reset()
    }
  }}
/>

<div
  class="relative flex items-center justify-between attendance-container flex-wrap {user_has_responded ? 'md:hover:shadow-md transition-shadow' : 'md:shadow-md'}"
>
  {@render attendance_text()}

  {#if user_has_responded || !attendance_limit_reached }
    <div>
      {#if $page.props.current_user}
        <Form {action} {method} class="w-full">
          <input type="hidden" name="event_id" value={event.id} />

          <div class="flex justify-between space-x-2 whitespace-nowrap">
            {#if current_attendance?.can_host && !current_attendance.is_host }
              {@render host_buttons()}
            {:else if show_buttons}
              {@render attendance_buttons()}
            {:else if current_attendance}
              {@render attendance_menu()}
            {/if}
          </div>
        </Form>

        <!-- going (ticketed) -->
        {#if event.ticket_price}
          <Modal bind:this={modals.ticket} id="attendance-ticket">
            <Pages.Body bind:this={ticket_pages}>
              <Pages.Page id="attendance-ticket-page">
                <TicketForm {additional_guests} {action} {method} on:success={() => modals.ticket.hide()} />
              </Pages.Page>

              {#if event.max_additional_guests}
                <AdditionalGuestsPage bind:additional_guests event={event} />
              {/if}
            </Pages.Body>
          </Modal>
        {/if}

        <!-- maybe -->
        <Modal bind:this={modals.maybe} id="attendance-maybe">
          <MaybeForm attendance={current_attendance} {action} {method} {onsuccess} bind_keyboard_submission />
        </Modal>

        <!-- cannot_go -->
        <Modal bind:this={modals.cannot_go} id="attendance-cannot-go">
          {@render cannot_go_form()}
        </Modal>
      {:else}
        <label for="attendance-rsvp" class="btn btn-primary h-10 min-h-10 shadow-md">
          <span class="i-feather-mail"></span>
          RSVP
        </label>

        <Modal bind:this={modals.rsvp} id="attendance-rsvp">
          {@render rsvp_form()}
        </Modal>
      {/if}
    </div>
  {/if}
</div>

{#snippet attendance_text()}
  <SlideTransition key={current_attendance?.status}>
    <p class="text-lg font-semibold truncate group">
      {#if current_attendance?.can_host && !current_attendance.is_host }
        You're invited to host this event.
      {:else if show_buttons }
        {#if attendance_limit_reached && current_attendance?.status !== 'maybe' }
          This event has reached the guest limit.
        {:else }
          { is_past ? 'Did you go?' : 'Are you going?' }
        {/if}
      {:else}
        {#if !is_past && current_attendance?.status === 'maybe'}
          <label for="attendance-maybe">
            You're <span class="text-secondary group-hover:underline">{parseFloat(current_attendance.probability.toFixed(2))}% likely</span> to go.
          </label>
        {:else if !is_past && event.ticket_price && current_attendance?.status === 'going' }
          You've bought a ticket!
        {:else if current_attendance}
          { attendance_label(current_attendance, { is_past }) }
        {/if}
      {/if}
    </p>
  </SlideTransition>
{/snippet}

{#snippet host_buttons()}
  <button
    type="submit"
    name="is_host"
    value="true"
    class="h-10 min-h-10 btn btn-primary"
  >
    <span class="i-feather-check"></span>
    Yes, host
  </button>

  <button
    type="submit"
    name="is_host"
    value="false"
    class="h-10 min-h-10 btn btn-error"
  >
    <span class="i-feather-x"></span>
    No
  </button>
{/snippet}

{#snippet attendance_buttons()}
  {#if event.ticket_price && !is_past}
    <label for="attendance-ticket" class="btn h-10 min-h-10 btn-primary">
      <span class="i-feather-check"></span>
      Yes, buy a ticket
    </label>
  {:else}
    <button
      type="submit"
      name="status"
      value="going"
      class="btn h-10 min-h-10 btn-primary"
    >
      <span class="i-feather-check"></span>
      Yes
    </button>
  {/if}

  {#if !is_past}
    <label for="attendance-maybe" class="btn h-10 min-h-10 btn-warning">
      <span class="i-ion-help"></span>
      Maybe
    </label>
  {/if}

  <label for="attendance-cannot-go" class="btn h-10 min-h-10 btn-error">
    <span class="i-feather-x"></span>
    No
  </label>
{/snippet}


{#snippet attendance_menu()}
  {@const button_class = `btn h-10 min-h-10 ${BUTTON_COLORS[current_attendance.status]}`}
  {@const label = attendance_label(current_attendance, { short: true, is_past })}

  <div class="relative">
    {#if current_attendance.status === 'going' && event.ticket_price}
      <div class={button_class}>{label}</div>
    {:else}
      <div class="relative dropdown dropdown-end dropdown-hover">
        <div tabindex="0" role="button" class={button_class}>
          {label}
          <span class="i-feather-chevron-down h-5 w-5"></span>
        </div>

        <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
        <div tabindex="0" class="dropdown-content pt-2 z-[1]">
          <ul class="p-2 shadow menu bg-base-100 rounded-box">
            {#if !event.ticket_price}
              <li>
                <button type="submit" name="status" value="going">
                  <span class="i-feather-check"></span>
                  { is_past ? 'Went' : 'Going' }
                </button>
              </li>
            {/if}

            {#if !is_past}
              <li>
                <label for="attendance-maybe">
                  <span class="i-ion-help-outline"></span>
                  Maybe
                </label>
              </li>
            {/if}

            <li>
              <label for="attendance-cannot-go" class="whitespace-nowrap">
                <span class="i-feather-x"></span>
                {is_past ? "Didn't go" : "Not going"}
              </label>
            </li>
          </ul>
        </div>
      </div>
    {/if}
  </div>
{/snippet}

{#snippet cannot_go_form()}
  <Form {action} {method} {onsuccess} bind_keyboard_submission>
    <div>
      <h3 class="text-2xl">Not going to <strong>{event.title}</strong>.</h3>

      <label class="form-control mt-1">
        <div class="label pb-0.5">
          <span class="label-text">Share an optional reason why:</span>
        </div>

        <!-- errors={ $page.form?.errors?.visible_location } -->

        <TextInput
          id="not-going-reason"
          name="reason"
          value={ current_attendance?.status === 'cannot_go' ? current_attendance.reason : '' }
          placeholder="I'm away on that date."
        />
      </label>
    </div>

    <div class="relative mt-3">
      <button
        type="submit"
        name="status"
        value="cannot_go"
        class="w-full btn btn-error"
      >Submit</button>

      <div class="absolute flex items-center inset-y-0 right-3 gap-1">
        <kbd class="kbd kbd-sm kbd-error">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-error">⏎</kbd>
      </div>
    </div>
  </Form>
{/snippet}

{#snippet rsvp_form()}
  <Form action="/events/{event.id}/users" {onsuccess}>
    <h3 class="text-lg">
      RSVP to <strong>{event.title}</strong> by entering your name and email, WhatsApp, Signal or Telegram number below.
    </h3>

    {#if event.ticket_price}
      <p class="text-lg">Tickets are <span class="text-sm text-gray-500">£</span>{ (event.ticket_price / 100).toFixed(2) }, and we'll send you a link to buy one.</p>
    {/if}

    <NewUserFields />

    <button
      type="submit"
      class="w-full mt-3 btn btn-primary"
    >Request invite</button>
  </Form>
{/snippet}

<style lang="postcss">
  @media screen(md) {
    .attendance-container {
      @apply ring-neutral-200 ring-1 rounded-box pl-5 pr-3 py-3 shadow-secondary/25;
    }
  }
</style>
