<script>
  import { page } from '@inertiajs/svelte'
  import { Modal, Form, TextInput } from '$lib/components'

  /** @type {import('$types').PageProps<'event'>} */
  let { event } = $derived($page.props)

  let restProps = $props()

  let open = $state(false)

  let delete_text = $derived.by(() => {
    if (event.entries?.length || event.attendances.length > 1) {
      let text = ''

      if (event.entries?.length) text += `${ event.attendances.length > 1 ? ',' : ' and' } remove the ${ event.entries.length } update${  event.entries.length === 1 ? '' : 's' }`

      if (event.attendances.length > 1) {
        text += ` and remove ${ event.attendances.length } ${ event.attendances.length === 1 ? 'person' : 'people' } from the guest list`
      }

      return text + '.'
    }

    return '.'
  })

  let event_title = $state('')
  let disabled = $derived(event_title.toLowerCase() !== event.title.toLowerCase())
</script>

<Modal {...restProps} bind:open>
  <h3 class="text-2xl font-medium">Are you absolutely sure?</h3>

  <p class="mt-3">This is <strong>irreversible</strong>. It will permanently delete {event.title}{delete_text}</p>

  <p class="mt-3">Please type <strong>{ event.title }</strong> to confirm.</p>

  <TextInput
    class="w-full mt-2 pr-3"
    placeholder={event.title}
    bind:value={event_title}
    required
    can_focus={open}
  />

  <Form action="/events/{ event.id }" method="delete" bind_keyboard_submission>
    <div class="relative mt-3">
      <button
        {disabled}
        class="btn btn-error w-full text-base"
        type="submit"
      >Delete this event</button>

      <div class="absolute flex items-center inset-y-0 right-3 gap-1">
        <kbd class="kbd kbd-sm kbd-error {disabled && 'kbd-disabled'}">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-error {disabled && 'kbd-disabled'}">⏎</kbd>
      </div>
    </div>
  </Form>
</Modal>
