<script>
  import { page } from '@inertiajs/svelte';
  import { NamePromptModal, TimeZoneChangedModal } from '$lib/components'
  import { store, current_time_zone } from '$lib/stores'

  /** @type {{ children: import('svelte').Snippet, current_user: import('$types').User, component: string }} */
  let { children, current_user } = $props()

  const show_name_prompt_modal = current_user && (!current_user.name || !current_user.is_name_set)

  const show_time_zone_modal = current_user && !show_name_prompt_modal && current_user.time_zone != current_time_zone && $store.ignored_time_zone !== current_time_zone
</script>

<div class="mx-auto px-4 sm:px-6 lg:px-8 { ['events/show', 'events/new', 'events/edit', 'events/index', 'profiles/show'].includes($page.component) ? 'max-w-6xl' : 'max-w-3xl' }">

  <div class="py-2 md:py-8 mx-auto">
    {@render children()}
  </div>
</div>

 {#if show_name_prompt_modal}
  <NamePromptModal />
{/if}

{#if show_time_zone_modal}
  <TimeZoneChangedModal user={current_user} />
{/if}
