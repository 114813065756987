<script>
  import { highlight, format_datetime } from '$lib/utilities'
  import { Avatar, Tooltip } from '$lib/components'
  import { page } from '@inertiajs/svelte'

  /** @type {{
    attendance: import('$types').Attendance,
    color: string,
    query: string,
    select_attendance: function(import('$types').Attendance):any
  }} */
  let { attendance, color, query, select_attendance } = $props()

  let { current_attendance } = $derived($page.props)

  const delivery_text = (statuses) => {
    if (statuses.error_at) {
      return 'Delivery error'
    }
    else if (statuses.read_at || statuses.device_at)
      return `Delivered at <span class="font-medium">${format_datetime(new Date((statuses.read_at || statuses.device_at) * 1000), 'HH:mm')}</span>`
    else if (statuses.server_at) {
      return `Server at <span class="font-medium">${format_datetime(new Date(statuses.server_at * 1000), 'HH:mm')}</span>`
    }
    else if (statuses.pending_at) {
      return `Pending at <span class="font-medium">${format_datetime(new Date(statuses.pending_at * 1000), 'HH:mm')}</span>`
    }
    else {
      return 'Delivery unknown'
    }
  }
</script>

<li class="flex items-center justify-between relative">
  <!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <label
    for="attendee-page"
    class="contents group cursor-pointer"
    onclick={ () => select_attendance(attendance)}
  >
    <div class="flex relative">
      <Avatar user={ attendance.user } {color} class="w-6 h-6" />

      {#if attendance.is_host}
        <div class="bg-white shadow shadow-secondary/20 h-4 w-4 absolute top-[-7px] right-[-7px] flex items-center justify-center rounded-full">
          <span class="i-mdi-crown-outline text-secondary h-3.5 w-3.5"></span>
        </div>
      {/if}

      {#if attendance.reason}
        <div class="bg-white shadow shadow-accent/20 h-4 w-4 absolute bottom-[-7px] right-[-7px] flex items-center justify-center rounded-full">
          <span class="i-mdi-fountain-pen-tip text-accent h-3.5 w-3.5"></span>
        </div>
      {/if}
    </div>

      <p class="ml-1.5 truncate relative">
        <span class="group-hover:underline {attendance.is_host && 'text-secondary'}">
          {@html highlight(attendance.user?.name, query)}
        </span>
      </p>
  </label>

  <div class="flex items-center justify-end whitespace-nowrap space-x-1.5 ml-4 flex-1">
    {#if attendance.probability}
      <span class="text-gray-500">({ parseFloat(attendance.probability.toFixed(2)) }% likely)</span>
    {/if}

    <div class="w-7">
      {#if attendance.updated_by_user_id && attendance.updated_by_user_id !== attendance.user_id}
        <Tooltip text="Updated by the host" container_class="dropdown-left dropdown-center" class="mr-2">
          <div class="flex-shrink-0 flex items-center justify-center shadow shadow-amber-500/25 border rounded-btn text-center h-7 w-7">
            <span class="i-feather-flag h-4.5 w-4.5 text-amber-500"></span>
          </div>
        </Tooltip>
      {/if}
    </div>

    {#if current_attendance?.is_host}
      <div class="w-7">
        {@render notification_statuses(attendance.notification?.statuses || {})}
      </div>
    {/if}
  </div>
</li>

{#snippet notification_statuses(statuses)}
  <Tooltip text={delivery_text(statuses)} container_class="dropdown-left dropdown-center" class="mr-2">
    <div class="flex-shrink-0 flex items-center justify-center shadow border rounded-btn text-center h-7 w-7">
      {#if statuses.error_at}
        <span class="i-ion-alert-circle-outline w-4 h-4 text-rose-500"></span>
      {:else if statuses.read_at || statuses.device_at}
        <span class="i-ion-checkmark-done w-4 h-4 {statuses.read_at ? 'text-sky-500' : 'text-gray-500'}"></span>
      {:else if statuses.server_at}
        <span class="i-ion-checkmark w-4 h-4 text-gray-500"></span>
      {:else if statuses.pending_at}
        <span class="i-ion-time-outline w-4 h-4 text-gray-500"></span>
      {:else}
        <span class="i-ion-help-outline w-4 h-4 text-gray-500"></span>
      {/if}
    </div>
  </Tooltip>
{/snippet}
