<script>
  import { page } from '@inertiajs/svelte'
  import { Combobox } from 'bits-ui'
  import { fly } from 'svelte/transition'
  import { Errors } from '$lib/components'
  import { debounce } from '$lib/utilities'

  let { current_user } = $derived($page.props)

  /** @type {{ event: Partial<import('$types').Event> }} */
  let { event = $bindable() } = $props()

  /** @type {'user' | 'google'} */
  let source = $state(event.location?.source || 'user')

  let source_id = $state(event.location?.source_id || current_user?.id)

  let formatted_address = $state(event.location?.formatted_address)

  /** @type {Array<{ id: string, value: string }>} */
  let location_options = $state([])

  let is_dirty = $state(false)

  const generate_session_token = () => crypto.randomUUID()

  let session_token = generate_session_token()

  let is_location_loading = $state(false)

  /** @param {InputEvent} _evt */
  const handle_input = (_evt) => {
    source = 'user'
    source_id = current_user?.id

    handle_autocomplete()
  }

  const handle_autocomplete = debounce(
    async () => {
      if (formatted_address.length < 3) return

      is_location_loading = true

      const { suggestions } = await fetch(`/google/places?input=${formatted_address}&session_token=${session_token}`).then(res => res.json())

      location_options = suggestions.map(({ placePrediction }) => ({
        id: placePrediction.placeId,
        value: placePrediction.text.text
      }))

      is_dirty = true
      is_location_loading = false
    },
    300
  )

  let filtered_locations = $derived(formatted_address
    ? location_options.filter((option) => option.value.toLowerCase().includes(formatted_address.toLowerCase()))
    : location_options
  )
</script>

<Combobox.Root
  items={filtered_locations}
  bind:inputValue={formatted_address}
  preventScroll={false}
  onSelectedChange={(selected) => {
    if (selected) {
      source = 'google'
      source_id = selected.value
      formatted_address = String(selected.label)
    }
  }}>
  <div class="relative">
    <Combobox.Input
      class="input input-bordered w-full"
      placeholder="Start typing&hellip;"
      aria-label="Start typing&hellip;"
      name="location"
      id="location"
      on:input={handle_input}
    />
    <Combobox.Label />

    {#if is_location_loading}
      <span class="loading loading-spinner loading-sm text-neutral/50 absolute end-3 top-1/2 -translate-y-1/2"></span>
    {/if}
  </div>

  {#if is_dirty && !is_location_loading}
    <Combobox.Content
      class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-full"
      sideOffset={8}
      transition={fly}
    >
      {#each filtered_locations as location (location.id)}
        <Combobox.Item
          class="rounded-btn py-2 px-4 text-sm font-normal data-[highlighted]:bg-base-200"
          value={location.id}
          label={location.value}
        >
          {location.value}
        </Combobox.Item>
      {:else}
        <span class="block px-5 py-2 text-sm text-muted-foreground">
          No results found
        </span>
      {/each}
    </Combobox.Content>
  {/if}
</Combobox.Root>

<Errors errors={$page.props.errors?.location} />

<input type="hidden" name="location_attributes[source]" value={source} />
<input type="hidden" name="location_attributes[source_id]" value={source_id} />
<input type="hidden" name="location_attributes[formatted_address]" value={formatted_address} />

