<script>
  import { untrack } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import { Form, Errors, Modal } from '$lib/components'
  import { notification_channels } from '$lib/config'

  let open = $state(false)

  let identifier = $state('')

  /** @type {{ channel: keyof typeof import('$lib/config').notification_channels }} */
  let { channel } = $props()

  let config = $derived(notification_channels[channel])

  let input_el = $state()

  $effect(() => {
    if (open) {
      untrack(() => setTimeout(() => input_el?.focus(), 50))
    }
  })
</script>

<Modal id="register-{config.name}-modal" bind:open>
  <h3 class="text-2xl font-medium">Register your {config.stylized}</h3>

  <p class="mt-3">Enter your {config.stylized} below to register for notifications.</p>

  <Form
    class="mt-3"
    action="/notification_channels"
    onsuccess={() => open = false}
    bind_keyboard_submission
  >
    <input type="hidden" name="type" value="{config.title}Channel" />

    <input
      type={config.type}
      class="input input-bordered w-full"
      placeholder={config.placeholder}
      name="identifier"
      bind:value={identifier}
      bind:this={input_el}
      onkeydown={(evt) => {
        if (evt.key === 'Escape') input_el.blur()
      }}
      required
    />

    <Errors errors={$page.props.errors?.identifier} />

    <div class="relative mt-3">
      <button
        type="submit"
        class="btn btn-primary w-full text-base"
        disabled={!identifier.length}
      >Add {config.stylized}</button>

      <div class="absolute flex items-center inset-y-0 right-3 gap-1">
        <kbd class="kbd kbd-sm kbd-primary {!identifier.length && 'kbd-disabled'}">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-primary {!identifier.length && 'kbd-disabled'}">⏎</kbd>
      </div>
    </div>
  </Form>
</Modal>