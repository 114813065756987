<script>
  import { untrack } from 'svelte'
  import { handle_shortcuts } from '$lib/utilities'

  /** @type {import('svelte/elements').HTMLInputAttributes & {
    value?: string
    can_focus?: boolean,
    focused?: boolean
    loading?: boolean,
    onkeydown?: import('svelte/elements').HTMLInputAttributes['onkeydown'],
    class?: string
    children?: import('svelte').Snippet
  }} */
  let {
    value = $bindable(''),
    can_focus = false,
    focused: is_focused = $bindable(false),
    onkeydown,
    class: class_name = '',
    loading = false,
    children,
    ...restProps
  } = $props()

  /** @type {HTMLInputElement | undefined} */
  let input_el = $state()

  $effect(() => {
    can_focus // dependency tracking

    untrack(() => {
      if (can_focus) {
        setTimeout(() => input_el?.focus(), 50)
      }
    })
  })

  handle_shortcuts({
    '/': () => can_focus && input_el?.focus()
  })
</script>

<label class="input input-bordered flex items-center gap-2 {class_name}">
  <input
    bind:this={input_el}
    bind:value
    class="grow"
    onfocus={() => is_focused = true}
    onblur={() => is_focused = false}
    onkeydown={(evt) => {
      onkeydown && onkeydown.call(window, evt)

      if (evt.key === 'Escape') input_el?.blur()
    }}
    {...restProps}
  />

  {@render children?.()}

  {#if loading}
    <span class="loading loading-spinner loading-sm text-muted"></span>
  {:else if can_focus && !is_focused}
    <kbd class="kbd kbd-sm">/</kbd>
  {/if}
</label>