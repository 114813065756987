<script>
  import { EventForm, RegnerateEventModal } from '$lib/components'

  /** @type {{ event: import('$types').Event }} */
  let { event: unreactive_event } = $props()

  let event = $state(unreactive_event)
</script>

<svelte:head>
  <title>{ event.title } • Occasionly</title>
</svelte:head>

<EventForm bind:event />

<RegnerateEventModal {event} />
