<script>
  import { page, router } from '@inertiajs/svelte'
  import { Modal, TextInput } from '$lib/components'
  import { handle_shortcuts, debounce, attendance_label} from '$lib/utilities'

  let open = $state(false),
    searching = $state(false),
    query = $state(''),
    dirty = $state(false),
    selected_index = $state(0)

  /** @type {import('$types').Event[]} */
  let events = $state([])

  const search = debounce(async (evt) => {
    if (!query.length) return

    searching = true

    events = await fetch(`/events/search?query=${query}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())

    dirty = true
    searching = false
  }, 400)

  const key_handlers = {
    mod_k: () => open = !open,
    ArrowUp: () => {
      selected_index = Math.max(selected_index - 1, 0)
    },
    ArrowDown: () => {
      selected_index = Math.min(selected_index + 1, events.length - 1)
    },
    Enter: () => {
      open = false
      query = ''
      router.visit(`/events/${events[selected_index].id}`)
    },
    Escape: () => {
      open = false
    }
  }

  handle_shortcuts(key_handlers)

  const BUTTON_COLORS = {
    invited: 'btn-primary',
    going: 'btn-primary',
    cannot_go: 'btn-error',
    payment_required: 'btn-warning',
    maybe: 'btn-warning'
  }
</script>

<Modal bind:open class="[&_.btn-circle]:hidden">
  <TextInput
    class="h-10 pr-2"
    bind:value={query}
    loading={searching}
    placeholder="Search your events..."
    can_focus={open}
    oninput={search}
    onkeydown={(evt) => {
      let key = evt.key

      if (evt.metaKey || evt.getModifierState('OS') /* Windows Key */) key = 'mod_' + key
      if (evt.ctrlKey) key = 'ctrl_' + key

      if (key in key_handlers) {
        evt.preventDefault()
        // @ts-ignore
        key_handlers[key].call(null, evt)
      }
    }}
  />

  <ul class="grid gap-3 [&>li:first-child]:mt-6">
    {#if events.length}
      {#each events as event, index}
        {@render result(event, index)}
      {/each}
    {:else if dirty}
      <li class="text-center text-muted">No events found.</li>
    {/if}
  </ul>
</Modal>

{#snippet result(event, index)}
  <li class="w-full min-w-0">
    <a
      href="/events/{event.id}"
      class="flex gap-2 justify-between h-[4.5rem] border-2 -m-2 p-2 w-[calc(100%+1rem)] rounded-box transition-colors {selected_index === index ? 'bg-base-200 border-neutral-300' : 'border-transparent'}"
      onmouseover={() => selected_index = index}
      onfocus={() => selected_index = index}
    >
      <div class="flex gap-3 w-full truncate min-w-0">
        <div
          class="aspect-[16/9] relative h-full bg-gradient-to-br from-slate-300 via-stone-300 to-slate-500 bg-cover bg-center rounded-btn"
        >
          {#if event.photo_url}
            <img
              class="h-full w-full object-cover rounded-btn {event.id && 'shadow-xl hover:shadow-2xl transition-shadow' }"
              src="{ event.photo_url }&width=800"
              alt="{ event.title }"
            />
          {/if}
        </div>
        <div class="truncate flex-1">
          <h3 class="text-base font-semibold truncate w-full">{event.title}</h3>
          <p class="truncate text-muted flex items-center">
            <span class="h-5 w-5 i-mdi-map-marker-outline -ml-1 opacity-50"></span>

            {event.location.formatted_address}
          </p>
        </div>
      </div>

      <div>
        {@render attendance_status(event.attendances.find(({ user_id }) => user_id === $page.props.current_user.id))}
      </div>
    </a>
  </li>
{/snippet}

{#snippet attendance_status(attendance)}
  <div class="btn btn-sm whitespace-nowrap {BUTTON_COLORS[attendance.status]}">
    {attendance_label(attendance, { short: true })}
  </div>
{/snippet}
