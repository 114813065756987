<script>
  /** @type {{
    children: import('svelte').Snippet
    text: string
    container_class?: string
    class?: string
  }} */
  let {
    children,
    text,
    container_class = '',
    class: class_name = ''
  } = $props()
</script>

<div role="status" class="gap-2 dropdown dropdown-hover {container_class}">
  <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
  <div tabindex="0" class="contents">
    {@render children?.()}
  </div>
 <!--  <div class="flex gap-1">
    <h3 class="text-lg font-semibold">Updates</h3>

    <span class="badge bg-white shadow shadow-amber-500/75 badge-sm border-transparent h-4 w-4 mt-1">
      <span class="text-amber-500">!</span>
    </span>
  </div> -->

  <div class="dropdown-content menu bg-base-100 rounded-btn z-[1] px-2 py-1 shadow-md border border-base-200 text-center {class_name}">
    <p>{@html text}</p>
  </div>
</div>