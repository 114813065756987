<script>
  import { onMount } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import { addDays, subMinutes, differenceInDays } from 'date-fns'
  import { toDate } from 'date-fns-tz'

  // import 'cally'

  import { TimePicker, DatePicker, Errors } from '$lib/components'
  import { time_zone } from '$lib/stores'
  import { format_datetime } from '$lib/utilities'

  onMount(() => import('cally'))

  /** @type {{ event: Partial<import('$types').Event> }} */
  let { event = $bindable() } = $props()

  /** @param {string | Date} val */
  const format_date = val => format_datetime(new Date(val), 'yyyy-MM-dd', $time_zone)

  /** @param {string | Date} val */
  const format_time = val => format_datetime(new Date(val), 'HH:mm', $time_zone)

  let start_date = $state(event.start_time ? format_date(event.start_time) : undefined),
        end_date = $state(event.end_time   ? format_date(event.end_time)   : undefined),
      start_time = $state(event.start_time ? format_time(event.start_time) : undefined),
        end_time = $state(event.end_time   ? format_time(event.end_time)   : undefined)

  $effect(() => {
    if (start_date) {
      event.start_time = toDate(`${start_date} ${event.is_all_day ? '00:00' : start_time || '00:00'}`, {
        timeZone: $time_zone
      }).toISOString()
    }
  })

  $effect(() => {
    if (end_date) {
      event.end_time = toDate(`${end_date} ${event.is_all_day ? '00:00' : end_time || '00:00'}`, {
        timeZone: $time_zone
      }).toISOString()
    }
  })

  $effect(() => {
    if (start_date && start_time && !event.is_all_day) {
      event.start_time = toDate(`${start_date} ${start_time}`, {
        timeZone: $time_zone
      }).toISOString()
    }
  })

  $effect(() => {
    if (end_date && end_time && !event.is_all_day) {
      event.end_time = toDate(`${end_date} ${end_time}`, {
        timeZone: $time_zone
      }).toISOString()
    }
  })

  /** @type {(0 | 15 | 30 | 45)[]} */
  const minutes = [0, 15, 30, 45]

  /** @param {number} time */
  const pad_time = time => String(time).padStart(2, '0')

  /** @type {string[]} */
  const time_options = Array.from({ length: 24 }).map((_, hour) =>
    minutes.map(min => `${pad_time(hour)}:${pad_time(min)}`)
  ).flat()

  /** @param {string | undefined} time */
  const rotate_time_options = (time = undefined) => {
    if (!time) return time_options

    const index = time_options.findIndex(_time => _time >= time)

    if (index > -1)
      return [...time_options.slice(index + 1), ...time_options.slice(0, index + 1)]

    return time_options
  }

  /** Set the start time to a useful time... */
  let start_time_options = $derived(start_date === format_date(new Date()) ? rotate_time_options(format_time(subMinutes(new Date(), 15))) : rotate_time_options())

  let end_time_options = $derived(rotate_time_options(start_time))

  /** @param {string} time */
  const parse_time = (time) => time.split(':').map(Number)

  /**
   * @param {string} start_time
   * @param {string} end_time
   */
  const end_time_duration = (start_time, end_time) => {
    let res = []

    if (start_date && end_date) {
      const days = differenceInDays(new Date(end_date), new Date(start_date))

      if (days > 1) res.push(`${days}d`)
    }

    const [start_h, start_m] = parse_time(start_time),
          [end_h, end_m]     = parse_time(end_time)

    const duration_mins = ((end_h * 60 + end_m) - (start_h * 60 + start_m) + 1440) % 1440

    const hours = Math.floor(duration_mins / 60),
           mins = duration_mins % 60

    if (res.length + hours + mins === 0) return '1d'

    if (hours) res.push(`${hours}h`)
    if (mins) res.push(`${mins}m`)

    return `${res.join(' ')}`
  }
</script>

<!-- <input type="hidden" name="_timezone" value={$time_zone}> -->
<input type="hidden" name="start_time" value={event.start_time}>
<input type="hidden" name="end_time" value={event.end_time}>

<div>
  <div class="label pt-0">
    <label class="block label-text font-medium text-base" for="_start_date">
      When

      <span class="text-muted max-xs:hidden">({format_datetime(new Date(), 'zzzz')})</span>
      <span class="text-muted xs:hidden">({format_datetime(new Date(), 'zzz')})</span>
    </label>

    <div class="flex items-center space-x-1.5">
      <label class="text-base text-gray-500" for="is_all_day">All day</label>

      <input type="hidden" name="is_all_day" value={false} />

      <input
        type="checkbox"
        id="is_all_day"
        name="is_all_day"
        class="toggle toggle-sm toggle-secondary"
        bind:checked={event.is_all_day}
        value={true}
      />
    </div>
  </div>

  <div class="flex justify-between flex-col space-y-2 mt-1">
    <div class="flex items-center flex-1 space-x-2">
     <!--  <calendar-range months="2">
        <svg
          aria-label="Previous"
          slot="previous"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M15.75 19.5 8.25 12l7.5-7.5"></path>
        </svg>
        <svg
          aria-label="Next"
          slot="next"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
        </svg>
        <div class="grid">
          <calendar-month></calendar-month>
          <calendar-month offset="1"></calendar-month>
        </div>
      </calendar-range> -->

      <DatePicker
        class="w-full pl-9"
        bind:value={start_date}
      >
        <div class="absolute left-3 text-muted">
          on
        </div>
      </DatePicker>

      <DatePicker
        class="w-full pl-12"
        bind:value={end_date}
        min={start_date}
      >
        <div class="absolute left-3 text-muted">
          until
        </div>
      </DatePicker>
    </div>

    {#if !event.is_all_day}
      <div class="flex items-center space-x-2">
        <div class="relative w-full">
          <TimePicker
            placeholder="12:00"
            class="pl-14"
            options={start_time_options}
            bind:value={start_time}
          />

          <div class="absolute top-1/2 -translate-y-1/2 left-4 pointer-events-none">
            <span class="text-muted">from</span>
          </div>
        </div>

        <div class="relative w-full">
          <TimePicker
            placeholder="15:00"
            class="pl-9"
            options={end_time_options}
            bind:value={end_time}
            onselect={(end_time) => {
              // If the end time is before the start time, we need to set the end date to the next day...
              if (start_date && start_time) {
                if (!event._end_date || start_date === event._end_date) {
                  if (end_time <= start_time) {
                    event._end_date = format_date(addDays(new Date(start_date), 1))
                  }
                  else if (!event._end_date) {
                    if (end_time > start_time) {
                      event._end_date = start_date
                    }
                  }
                }
              }
            }}
            let:option
          >
            <div class="flex w-full items-center justify-between">
              {option}

              {#if start_time}
                <span class="text-xs text-muted">({end_time_duration(start_time, option)})</span>
              {/if}
            </div>
          </TimePicker>

          <div class="absolute top-1/2 -translate-y-1/2 left-4 pointer-events-none">
            <span class="text-muted">to</span>
          </div>
        </div>
      </div>
    {/if}
  </div>

  <Errors errors={$page.props.errors?.start_time || $page.props.errors?.end_time} />
</div>

<style lang="postcss">
  input[type="date"] {
    @apply absolute inset-0 opacity-0;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    @apply absolute inset-0 w-full h-full;
  }

  .date-picker-container {
    @apply relative rounded-btn flex items-center px-3 h-10 border whitespace-nowrap w-full;
  }
</style>
