<script>
  import { Form, Modal } from '$lib/components'
  import { handle_shortcuts } from '$lib/utilities'

  /** @type {{ event: import('$types').Event }} */
  let { event } = $props()

  let open = $state(false)

  /** @type {string} */
  let value = $state('')

  let is_valid = $derived(!!value.trim().length)

  let text_area_el = $state()

  let is_text_area_focused = $state(false)

  $effect(() => {
    if (open) {
      setTimeout(() => {
        text_area_el.focus()
        text_area_el.setSelectionRange(text_area_el.value.length, text_area_el.value.length)
      }, 50)
    }
  })

  handle_shortcuts({
    'r': () => open = true,
    '/': () => open && text_area_el.focus()
  })
</script>

<Modal id="regenerate-event-modal" bind:open>
  <h3 class="text-2xl font-semibold flex items-center gap-1">
    What would you like to change?

    <span class="i-heroicons-sparkles text-secondary"></span>
  </h3>

  <Form
    action="/events/{event.id}/regenerate"
    method="patch"
    class="space-y-3 mt-3"
    onsuccess={() => {
      open = false
      value = ''
    }}
    bind_keyboard_submission
  >
    <label class="block relative flex-1">
      <textarea
        bind:this={text_area_el}
        name="prompt"
        class="textarea textarea-bordered w-full text-base"
        placeholder="Change the location to Wuthering Heights, and allow guests to invite others."
        rows={3}
        onfocus={() => is_text_area_focused = true}
        onblur={() => is_text_area_focused = false}
        bind:value
        onkeydown={(evt) => {
          if (evt.key === 'Escape') text_area_el.blur()
        }}
      ></textarea>

      {#if !is_text_area_focused}
        <div class="absolute flex items-center top-2 right-2">
          <kbd class="kbd kbd-sm">/</kbd>
        </div>
      {/if}
    </label>

    <div class="relative">
      <button
        type="submit"
        class="btn btn-primary w-full text-base"
        disabled={!is_valid}
      >Update event</button>

      <div class="absolute flex items-center inset-y-0 right-3 gap-1">
        <kbd class="kbd kbd-sm kbd-primary {!is_valid && 'kbd-disabled'}">⌘</kbd>
        <kbd class="kbd kbd-sm kbd-primary {!is_valid && 'kbd-disabled'}">⏎</kbd>
      </div>
    </div>
  </Form>
</Modal>
